.btn-primaryGold {
  background-color: #F0C556;
  color: black;
}

.btn-primaryGold:not([disabled]):hover {
  background-color: rgb(240, 197, 86, .8);
}

.btn-primaryGold:focus {
  box-shadow: 0px 0px 3px #F0C556;
}

.btn-outlineGold {
  border: 1px solid #F0C556;
  box-sizing: border-box;
  background-color: transparent;
  color: white;
}

.btn-outlineGold:hover {
  background-color: #F0C556;
  color: black;
}

.btn-outlineGold:focus {
  box-shadow: 0px 0px 3px #F0C556;
  border: 1px solid #F0C556;
}

.modalDark > .modal-content > .modal-header {
  background-color: #232327;
  color: white;
}

.modalDark > .modal-content > .modal-header > .close {
  color: white;
}

.modalDark > .modal-content > .modal-body {
  background-color: #232327;
  color: white;
}

.modalDark > .modal-content > .modal-body .form-control-plaintext {
  color: white;
}

.modalDark > .modal-content > .modal-body .form-label {
  color: gray;
}

.modalDark > .modal-content > .modal-footer {
  background-color: #232327;
  color: white;
}

.mobileNoModal {
  margin-left: 15px;

  @media (max-width: 1050px) {
    margin-left: 0;
  }
}

button:active {
  outline-style: none;
  outline-color: gold;
  outline-width: '1px';
}

.btn-linkGold {
  margin-top: 5px;
  font-weight: 400;
  color: #F0C556;
  text-decoration: none;
  padding: 0;
}

.btn-linkGold:hover {
  color: #F0C556;
}

.btn-linkGold:focus {
  box-shadow: 0px 0px 3px #F0C556;
}

.btn-linkPrimary {
  margin-top: 5px;
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
  padding: 0;
}

.btn-linkPrimary:hover {
  color:  #007bff;
}
